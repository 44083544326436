
<template>
  <div class="text-center">
    <v-dialog
      v-model="show"
      width="500"
      persistent
    >
      <v-card>
          <v-card-title>ยกเลิกการตั้งค่าวันหมดอายุไฟล์ <v-spacer></v-spacer>
            <v-icon size="30" class="pr-2" :color="color.theme">mdi-file-clock</v-icon>
          </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="text-center pt-4">
           <v-icon color="amber">error_outline</v-icon>คุณต้องการยกเลิกการตั้งค่าวันหมดอายุไฟล์หรือไม่? <v-icon color="amber">error_outline</v-icon>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" outlined @click="$emit('closedialog')">
            {{ $t("uploadfolder.buttonclose") }}
          </v-btn>
          <v-btn dark @click="fn_btn_api()" :color="color.theme">
            {{ $t("checkpassword.ok") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

// import lib
import Swal from "sweetalert2/dist/sweetalert2.js";
import { mapState, mapGetters } from "vuex";
import InfiniteLoading from "vue-infinite-loading";
import "vue-full-screen-file-drop/dist/vue-full-screen-file-drop.css";
import VueFullScreenFileDrop from "vue-full-screen-file-drop";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
export default {
    methods:{
        // ยิง api ทำการยกเลิกการตั้งค่าวันหมดอายุ
       async fn_btn_api(){
          let payload = {
              expired_time:"",
              inbox_id:[this.filedata.inbox_id],
              status:"remove_expired",
              sender_id:this.dataAccountActive.business_info.business_id
          }
          let auth = await gbfGenerate.generateToken();
         await this.axios
             .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/set_expired_inbox", payload,{headers: { Authorization: auth.code },})
             .then((response) =>{
                 if(response.data.status === "OK"){
                    this.$emit('closedialogandrefresh')
                    Toast.fire({
                      icon: "success",
                      title: "ยกเลิกการตั้งค่าสำเร็จ",
                    });
                 }
             })
        },
    },
    props:["show","filedata"],
    computed: {
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataAccountId",
    ]),
  }
}

</script>